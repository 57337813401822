export class Excursions {
  id: string;
  theme: string;
  pictures: string;
  description: string;
  prix: string;
  side: string;
  country:string;
  cost:string;
  discount:number;
  videoUrl:string;
  excursionsId:string;
  createdAt:string;
 

  constructor(obj: Excursions | any = {}) {
    this.id = obj.id || '';
    this.theme = obj.theme || '';
    this.pictures = obj.pictures || '';
    this.description = obj.description || '';
    this.side = obj.side || '';
    this.cost = obj.cost || '';
    this.videoUrl = obj.videoUrl || '';
    this.discount = obj.discount || 0;
    this.country = obj.country || '';
    this.excursionsId = obj.excursionsId || '';
    this.createdAt = obj.createdAt || '';
   
  }
}
