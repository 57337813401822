import { Injectable } from '@angular/core';
import { User } from '../store/models/user.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
constructor() { }

  getFirstname(){
    return localStorage.getItem('firstname');
  }

  getLastname(){
    return localStorage.getItem('lastname');
  }

  getUserId(){
    return localStorage.getItem('userid');
  }

  getOccupationId(){
    return localStorage.getItem('occupationid');
  }

  getRoleId(){
    return localStorage.getItem('roleid');
  }

 setFirstname(variableName:string, data:string){
    localStorage.setItem(variableName, data);
  }

 setLastname(variableName:string, data:string){
    localStorage.setItem(variableName, data);
  }

 setUserId(variableName:string, data:string){
    localStorage.setItem(variableName, data);
  }

  setOccupationId(variableName:string, data:number){
   localStorage.setItem(variableName, data+"");
  }

  setRoleId(variableName:string, data:number){
   localStorage.setItem(variableName, data+"");
  }

  
getToken() {
      return localStorage.getItem('userid');
  }
clearStorage() {
      localStorage.clear();
  }
}