import { Action } from '@ngrx/store';
import { Occupation } from '../models/occupation.model';

export enum OccupationActionTypes {
  OCCUPATION_ADD_ITEM = '[OCCUPATION] Add Item',
  OCCUPATION_REMOVE_ITEM = '[OCCUPATION] Remove Item',
}

export class OccupationAddItemAction implements Action {
  readonly type = OccupationActionTypes.OCCUPATION_ADD_ITEM;

  constructor(public payload: Occupation) {}
}

export class OccupationRemoveItemAction implements Action {
  readonly type = OccupationActionTypes.OCCUPATION_REMOVE_ITEM;

  constructor(public payload: number) {}
}

export type OccupationAction =
  | OccupationAddItemAction
  | OccupationRemoveItemAction;
