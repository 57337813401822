import { Occupation } from '../models/occupation.model';
import {
  OccupationActionTypes,
  OccupationAction,
} from '../actions/occupation.action';

const initialState: Array<Occupation> = [new Occupation()];

export function OccupationReducer(
  state: Array<Occupation> = initialState,
  action: OccupationAction
) {
  switch (action.type) {
    case OccupationActionTypes.OCCUPATION_ADD_ITEM:
      return [...state, action.payload];
    default:
      return state;
  }
}
