import { Role } from '../models/role.model';
export const ADD_ROLE = 'ADD_ROLE';
export function RoleReducer(state: Role[] = [], action) {
  switch (action.type) {
    case ADD_ROLE:
      return [...state, action.payload];
    default:
      return state;
  }
}
