import { User } from '../models/user.model';
import { UserActionTypes, UserAction } from '../actions/user.action';

const initialState: Array<User> = [new User()];

export function UserReducer(
  state: Array<User> = initialState,
  action: UserAction
) {
  switch (action.type) {
    case UserActionTypes.USER_ADD_ITEM:
      return [...state, action.payload];
    default:
      return state;
  }
}

