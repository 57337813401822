import { Action } from '@ngrx/store';
import { Excursions } from '../models/excursions.model';

export enum ExcursionsActionTypes {
  EXCURSIONS_ADD_ITEM = '[EXCURSIONS] Add Item',
  EXCURSIONS_REMOVE_ITEM = '[EXCURSIONS] Remove Item',
}

export class ExcursionsAddItemAction implements Action {
  readonly type = ExcursionsActionTypes.EXCURSIONS_ADD_ITEM;

  constructor(public payload: Excursions[]) {}
}

export class ExcursionsRemoveItemAction implements Action {
  readonly type = ExcursionsActionTypes.EXCURSIONS_REMOVE_ITEM;

  constructor(public payload: number) {}
}

export type ExcursionsAction = ExcursionsAddItemAction | ExcursionsRemoveItemAction;
