export class Booking {
  id: string;
  excursionsId:String;
  userId:String;
  description: String;
  fullName: String;
  address: String;
  phone: String;
  hotel:String;
  nombrePerson:String;
  prix:number;
  roomNumber:String;
  datedebut:String;
  paiementType:String;
  createdAt:String;
  paymentDate:String;
  payment:String;
  currency:String;
  side:String;
  country:String;
  cost:String;
  discount:number;
 

  constructor(obj: Booking | any = {}) {
    this.id = obj.id || "";
    this.excursionsId = obj.excursionsId || '';
    this.userId = obj.userId || '';
    this.address = obj.address || '';
    this.payment = obj.payment || 'No';
    this.paymentDate = obj.paymentDate || '';
    this.hotel = obj.hotel || '';
    this.fullName = obj.fullName || '';
    this.description = obj.description || '';
    this.phone = obj.phone || '';
    this.nombrePerson = obj.nombrePerson || '';
    this.prix = obj.prix || '';
    this.roomNumber = obj.roomNumber || '';
    this.datedebut = obj.datedebut || '';
    this.createdAt = obj.createdAt || '';
    this.paiementType = obj.paiementType || '';
    this.currency = obj.currency || '';
    this.side = obj.side || '';
    this.country = obj.country || '';
    this.cost = obj.cost || '';
    this.discount = obj.discount || 0;
   
  }
}
