import { Profil } from '../models/profil.model';
import { ProfilActionTypes, ProfilAction } from '../actions/profil.action';

const initialState: Array<Profil> = [new Profil()];

export function ProfilReducer(
  state: Array<Profil> = initialState,
  action: ProfilAction
) {
  switch (action.type) {
    case ProfilActionTypes.PROFIL_ADD_ITEM:
      return [...state, action.payload];
    default:
      return state;
  }
}