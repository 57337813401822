import { Action } from '@ngrx/store';
import { Booking } from '../models/booking.model';

export enum BookingActionTypes {
  BOOKING_ADD_ITEM = '[BOOKING] Add Item',
  BOOKING_REMOVE_ITEM = '[BOOKING] Remove Item',
}

export class BookingAddItemAction implements Action {
  readonly type = BookingActionTypes.BOOKING_ADD_ITEM;

  constructor(public payload: Booking[]) {}
}

export class BookingRemoveItemAction implements Action {
  readonly type = BookingActionTypes.BOOKING_REMOVE_ITEM;

  constructor(public payload: number) {}
}

export type BookingAction = BookingAddItemAction | BookingRemoveItemAction;
