export class User {
  id: string;
  firstName: string;
  lastName: string;
  userid: string;
  statut: number;
  activation: number;
  createdAt: number;
  updateAt:number;
  occupationId:number;
  firstLogin:number;
  roleId:number;

  constructor(obj: User | any = {}) {
    this.id = obj.id || '';
    this.firstName = obj.firstName || '';
    this.lastName = obj.lastName || '';
    this.userid = obj.userid || '';
    this.statut = obj.statut || '';
    this.activation = obj.activation || '';
    this.createdAt = obj.createdAt || '';
    this.updateAt = obj.updateAt || '';
    this.firstLogin = obj.firstLogin || '';
    this.occupationId = obj.occupationId || '';
    this.roleId = obj.roleId || '';
  }
}
