import { Action } from '@ngrx/store';
import { Profil } from '../models/profil.model';

export enum ProfilActionTypes {
  PROFIL_ADD_ITEM = '[PROFIL] Add Item',
  PROFIL_REMOVE_ITEM = '[PROFIL] Remove Item',
}

export class ProfilAddItemAction implements Action {
  readonly type = ProfilActionTypes.PROFIL_ADD_ITEM;

  constructor(public payload: Profil) {}
}

export class ProfilRemoveItemAction implements Action {
  readonly type = ProfilActionTypes.PROFIL_REMOVE_ITEM;

  constructor(public payload: number) {}
}

export type ProfilAction = ProfilAddItemAction | ProfilRemoveItemAction;
