import { Action } from '@ngrx/store';
import { User } from '../models/user.model';

export enum UserActionTypes {
  USER_ADD_ITEM = '[USER] Add Item',
  USER_REMOVE_ITEM = '[USER] Remove Item',
}

export class UserAddItemAction implements Action {
  readonly type = UserActionTypes.USER_ADD_ITEM;

  constructor(public payload: User) {}
}

export class UserRemoveItemAction implements Action {
  readonly type = UserActionTypes.USER_REMOVE_ITEM;

  constructor(public payload: number) {}
}

export type UserAction = UserAddItemAction | UserRemoveItemAction;
