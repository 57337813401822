import { Excursions } from '../models/excursions.model';
import { ExcursionsActionTypes, ExcursionsAction } from '../actions/excursions.action';

const initialState: Array<Excursions> = [new Excursions()];

export function ExcursionsReducer(
  state: Array<Excursions> = initialState,
  action: ExcursionsAction
) {
  switch (action.type) {
    case ExcursionsActionTypes.EXCURSIONS_ADD_ITEM:
      return [...state, action.payload];
    default:
      return state;
  }
}

