import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OccupationReducer } from './store/reducers/occupation.reducer';
import { RoleReducer } from './store/reducers/role.reducer';
import { UserReducer } from './store/reducers/user.reducer';
import { BookingReducer } from './store/reducers/booking.reducer';
import { ExcursionsReducer } from './store/reducers/excursions.reducer';
import { ProfilReducer } from './store/reducers/profil.reducer';
import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AuthService } from '../app/services/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        ConfirmDialogModule,
        DialogModule,
        environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
        StoreModule.forRoot({
            user: UserReducer,
            role: RoleReducer,
            profil: ProfilReducer,
            occupation: OccupationReducer,
            booking: BookingReducer,
            excursions: ExcursionsReducer,
          }),
          ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
          }),
          StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
          }),
    ],
    declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [AuthService,AuthGuard,MessageService,ConfirmationService],
    bootstrap: [AppComponent]
})
export class AppModule {}
