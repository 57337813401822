import { Booking } from '../models/booking.model';
import { BookingActionTypes, BookingAction } from '../actions/booking.action';

const initialState: Array<Booking> = [new Booking()];

export function BookingReducer(
  state: Array<Booking> = initialState,
  action: BookingAction
) {
  switch (action.type) {
    case BookingActionTypes.BOOKING_ADD_ITEM:
      return [...state, action.payload];
    default:
      return state;
  }
}

